import { captureException, Severity, withScope } from '@sentry/browser';
import { AxiosError, AxiosRequestConfig } from 'axios';

/**
 * Interceptors to be added to an axios service object.
 * For intercepts handling errors, be sure to return a Promise.reject
 * or else the error gets silenced, effectively skipping all other "error" interceptors
 * and returning 'undefined' to the API caller (silencing the error)
 */

export type Redirects = {
  401?: string;
};

export function sentryInterceptor(error: AxiosError): Promise<AxiosError> {
  const statusCode = Number(error.code || error?.response?.status);
  const isWarning = [404, 409].includes(Number(statusCode));

  // Axios is throwing a Request Aborted error message on request.onabort callback.
  // See request.onabort https://github.com/axios/axios/blob/master/lib/adapters/xhr.js
  // Handle browser request cancellation (as opposed to a manual cancellation)
  const isRequestAbortedByBrowser = error.message === 'Request aborted' && !!error.isAxiosError;

  withScope((scope) => {
    if (isWarning) {
      scope.setLevel(Severity.Warning);
    }

    // This is to avoid sending request aborted error to sentry.
    if (isRequestAbortedByBrowser) return;

    captureException(error);
  });

  return Promise.reject(error);
}

export const addBasicAuthorizationIntoHeaderInterceptor =
  (callbackKey?: string) =>
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (callbackKey && config.headers) {
      config.headers['Authorization'] = `Basic ${callbackKey}`;
    }

    return config;
  };

export const addJwtIntoHeaderInterceptor = (token?: string) => (config: AxiosRequestConfig) => {
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

export const addProductIntoHeaderInterceptor = (product: string) => (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers['BL-Product'] = product;
  }
  return config;
};

export const addTraceIntoHeaderInterceptor = (traceId?: string) => (config: AxiosRequestConfig) => {
  if (traceId && config.headers) {
    config.headers['bl-trace-id'] = traceId;
  }

  return config;
};

export const addTestCafeIntoHeaderInterceptor = () => (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers['BL-TestCafe'] = 'yes';
  }
  return config;
};

export const addTimezoneIntoHeaderInterceptor = () => (config: AxiosRequestConfig) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (config.headers) {
    config.headers['bl-timezone'] = timezone || 'America/Toronto';
  }
  return config;
};

// ⚠️ If you update these header keys, also update the same keys in the core backend
const RELEASE_VERSION_HTTP_HEADER_KEY = 'bl-release-version';
export const RELOAD_REQUIRED_HTTP_HEADER_KEY = 'bl-reload-required';
/** Enforces the release version is set in request headers and that the page reloads if signaled by a server response. */
export const enforceReleaseVersion = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.headers[RELOAD_REQUIRED_HTTP_HEADER_KEY] === 'true') {
    window.location.reload();
  }

  return Promise.reject(error);
};

export const addReleaseVersionHeader = (releaseVersion: string) => (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers[RELEASE_VERSION_HTTP_HEADER_KEY] = releaseVersion;
  }
  return config;
};

export const addErrorRedirectInterceptor =
  (redirects: Redirects) => (error: AxiosError<{ code: number; name: string }>) => {
    const errorData = error?.response?.data;
    const isNotAuthenticatedThrown = errorData?.code === 401 && errorData?.name === 'NotAuthenticated';
    const isAuthenticationError = isNotAuthenticatedThrown || error.response?.status == 401;
    const currentPath = window.location.pathname;

    if (isAuthenticationError && redirects[401] && currentPath !== redirects[401]) {
      window.location.href = redirects[401];
    }
    return Promise.reject(error);
  };
