import _ from 'lodash';
import queryString from 'query-string';

import { PageQueryOptions, SortParams } from '@breathelife/types';

export function buildPageQueryOptions<T>({ $limit, $page, $sort, $search }: PageQueryOptions<T>): PageQueryOptions<T> {
  return {
    ...(typeof $limit !== 'undefined' ? { $limit } : {}),
    ...(typeof $page !== 'undefined' ? { $page } : {}),
    ...(typeof $sort !== 'undefined' ? { $sort } : {}),
    ...(typeof $search !== 'undefined' ? { $search } : {}),
  };
}

export function convertObjectToQueryString<T>(options: { [key: string]: any } & { $sort?: SortParams<T> }): string {
  const { $sort, ...rest } = options;
  let sortQuery = undefined;
  const query = queryString.stringify(rest, { arrayFormat: 'comma' });
  if ($sort) {
    sortQuery = buildSortQuery($sort);
  }
  const queries = _.compact([query, sortQuery]);
  return `?${queries.join('&')}`;
}

export function buildSortQuery<T>(sortOptions: SortParams<T>): string {
  const { field, direction } = sortOptions;

  return `$sort[${field}]=${direction}`;
}
